<template>
  <b-navbar toggleable="lg">
    <b-collapse id="navbar-toggle-collapse" is-nav>
      <ul class="menu-items">
        <li class="menu-item">
          <router-link to="/" active-class="active">
            NLC Vesting
          </router-link>
        </li>
      </ul>
    </b-collapse>
  </b-navbar>
</template>

<style scoped lang="scss">
.navbar {
    padding: 0.5rem 0;
}
.menu-items {
  padding: 14px 0;
  list-style: none;
  width: 100%;

  .menu-item {
    margin-top: 26px;
    color: #fff;
    
    cursor: pointer;
    a.router-link-exact-active.active {
        border-left: 4px solid #2E2D66;
        padding: 13px 25px;
        font-weight: bold;
        color: #111;
        text-align: left;
        background-color: #fff;
        width: 100%;
        display: block;
    }
    a {
      font-weight: bold;
      color: #111;
      padding-left: 30px;
      text-decoration: none;
    }
  }
}
@media (max-width: 600px) {
  .menu-items {
    display: block !important;
    text-align: center;
  }
}
</style>

<script>
export default {
  name: 'Menu',
};
</script>
