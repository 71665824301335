<template>
  <section class="main">
    <header-layout />
    <section class="section-body container-fluid d-flex flex-1">
      <div class="row flex-1 flex-mb">
        <div class="col-xl-2 pl-0 pr-0 left-content">
          <menu-layout />
        </div>
        <div class="col-xl-10 right-content d-flex flex-column">
          <div class="router-view flex-1">
            <router-view />
          </div>
          <footer-layout />
        </div>
      </div>
    </section>
    <toast-notify />
  </section>
</template>

<style scoped lang="scss">
  @media (max-width: 600px) {
    .flex-mb {
      flex-direction: column;
    }
  }
</style>

<script>
import HeaderLayout from '@/components/base/Header.vue';
import MenuLayout from '@/components/base/Menu.vue';
import FooterLayout from '@/components/base/Footer.vue';
import ToastNotify from '@/components/Notification.vue';

export default {
  name: 'App',
  components: {
    HeaderLayout,
    MenuLayout,
    FooterLayout,
    ToastNotify,
  },
};
</script>
