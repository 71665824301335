import numFormat from 'number-format.js';
import { getAccountData, getPools } from '@/utils/vesting';
import { WALLET_CONNECT } from '@/utils/constants';
import token from '@/domain/vesting/token';

export default {
  computed: {
    account() {
      return this.$store.state.account;
    },
    web3() {
      return this.$store.state.web3;
    },
    displayAddress() {
      const address = this.$store.state.account?.address;
      if (address?.length > 10) {
        return `${address.substr(0, 6)}...${address.substr(-4)}`;
      }

      return address;
    },
  },

  beforeDestroy() {
    clearInterval(this.poolsDataInterval);

    if (this.$store.state.isLogin) {
      this.web3.instance.currentProvider.removeListener(
        'accountsChanged',
        this.handleAccountChanged
      );
      this.web3.instance.currentProvider.removeListener(
        'chainChanged',
        this.handleChainChanged
      );
      this.web3.instance.currentProvider.removeListener(
        'disconnect',
        this.handleDisconnected
      );
    }
  },

  methods: {
    toFormattedToken(num) {
      return numFormat(
        '#,##0.',
        this.web3.instance.utils.fromWei(num, 'ether')
      );
    },

    toFormatNumber(num) {
      return numFormat(
        '#,##0.',
        num
      );
    },

    convertWeiToEther(num) {
      return this.web3.instance.utils.fromWei(num);
    },

    convertEtherToWei(num) {
      return this.web3.instance.utils.toWei(num).toString();
    },

    async handleAccountChanged(accounts) {
      const accountData = await getAccountData(this.web3.instance, token, accounts);

      if (accountData) {
        this.$store.dispatch('account/updateAccountData', accountData);

        getPools(this.web3.instance, this.account.address)
          .then((result) => {
            this.$store.dispatch('vesting/updatePools', result);
          })
          .catch((e) => console.error(e));

        this.$root.$emit('global-notification', {
          title: 'Account Connected',
          type: 'success',
          content: 'Your account is connected!',
        });
      } else {
        this.$root.$emit('global-notification', {
          title: 'Connect Failed',
          type: 'error',
          content: 'Please check the network!',
        });
      }
    },

    handleChainChanged() {
      window.location.reload();
    },

    handleDisconnected() {
      this.$store.dispatch('onLogout');
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      let keyCode = evt.keyCode ? evt.keyCode : evt.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        return evt.preventDefault();
      } else {
        return true;
      }
    },

    async onLogout() {
      this.web3.provider == WALLET_CONNECT
        ? await this.web3.instance.currentProvider.disconnect()
        : this.$store.dispatch('onLogout');
    },
  },
};
