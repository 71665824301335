<template>
  <a href="#" class="btn btn-logout" @click.prevent="onLogout"> Logout </a>
</template>

<style scoped lang="scss">
.btn {
  border: none;
  color: #fff;
  background-color: #323272;
  width: 100%;
  height: 81px;
  font-size: 21px;
  border-radius: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff !important;
  }
}
</style>

<script>
import { WALLET_CONNECT } from '@/utils/constants';

export default {
  name: 'AccountWallet',

  computed: {
    web3() {
      return this.$store.state.web3;
    },
  },

  methods: {
    async onLogout() {
      this.web3.provider == WALLET_CONNECT
        ? await this.web3.instance.currentProvider.disconnect()
        : this.$store.dispatch('onLogout');
    },
  },
};
</script>
