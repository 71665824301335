import { Decimal as BigJs } from 'decimal.js';
export const WALLET_CONNECT = 'wallet_connect';

export const PRODUCTION = 'production';

export const DEVELOPMENT = 'development';

export const METAMASK = 'metamask';

export const MAX_UINT256 = new BigJs(2).pow(256).minus(1).toFixed(0);
