<template>
  <div class="body">
    <p class="connect-wallet-title text-center">
      Select the provider
    </p>
    <p class="connect-wallet-msg text-center">
      Choose a provider to use the bridge and transfer coins
    </p>
    <p class="connect-wallet-msg text-center" />
    <div class="connect-wallet-buttons">
      <a href="#" class="btn btn-none-active btn-metamask" @click.prevent="connectWithMetamask">
        <span class="btn-icon fox-icon" />Metamask
      </a>
      <a href="#" class="btn btn-none-active btn-walltetconnect mt-5" @click.prevent="connectWithWalletConnect">
        <span class="btn-icon w-icon" />Walletconnect
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.connect-wallet-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 45px;

  .btn {
    border: none;
    color: #fff;
    background: #323272;
    width: 295px;
    height: 81px;
    font-size: 21px;
    border-radius: 15px;
    margin-top: 15px;

    @media (max-width: 414px) {
      font-size: 30px;
      font-weight: 600;
    }
  }
}

.btn.btn-connect-wallet {
  width: 164px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body {
  padding-bottom: 2rem;
}
</style>

<script>
import { getAccountData } from '@/utils/vesting';
import { WALLET_CONNECT, METAMASK } from '@/utils/constants';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import vestingNetwork from '@/domain/vesting/network';
import vestingToken from '@/domain/vesting/token';

export default {
  name: 'ConnectToWallet',

  computed: {
    web3() {
      return this.$store.state.web3;
    },
  },

  methods: {
    async connectWithMetamask() {
      try {
        if (!window.ethereum) {
          return;
        }

        const instance = new Web3(Web3.givenProvider);
        const chainId = await instance.eth.net.getId();
        sessionStorage.setItem('provider', METAMASK);

        this.$store.dispatch('web3/update', {
          instance,
          chainId,
          provider: METAMASK,
        });

        if (this.getNetwork(this.$route.name)?.chainId === chainId) {
          const token = this.getToken(this.$route.name);
          let accountData = await getAccountData(this.web3.instance, token);

          if (accountData) {
            sessionStorage.setItem('address', accountData.address);
            window.location.reload();
          } else {
            this.$root.$emit('global-notification', {
              title: 'Connect Failed',
              type: 'error',
              content: 'Please check the token network!',
            });
          }
        } else {
          this.$root.$emit('global-notification', {
            title: 'Connect Failed',
            type: 'error',
            content: 'Please check the token network!',
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    async connectWithWalletConnect() {
      const provider = new WalletConnectProvider(this.getNetwork(this.$route.name));

      try {
        await provider.enable();
      } catch (e) {
        window.location.reload();
      }

      const instance = new Web3(provider);
      const chainId = await instance.eth.net.getId();
      sessionStorage.setItem('provider', WALLET_CONNECT);

      this.$store.dispatch('web3/update', {
        instance,
        chainId,
        provider: WALLET_CONNECT,
      });

      if (this.getNetwork(this.$route.name)?.chainId === chainId) {
        const token = this.getToken(this.$route.name);
        const accountData = await getAccountData(this.web3.instance, token);

        if (accountData) {
          sessionStorage.setItem('address', accountData.address);

          window.location.reload();
        } else {
          this.$root.$emit('global-notification', {
            title: 'Connect Failed',
            type: 'error',
            content: 'Please check the network!',
          });
        }
      }
    },

    getNetwork(routeName) {
      switch (routeName) {
        case 'Vesting':
          return {
            chainId: vestingNetwork.chainIds[0],
            rpc: {
              [vestingNetwork.chainIds[0]]: vestingNetwork.rpcUrls[0],
            },
          };
        default:
          return {
            chainId: vestingNetwork.chainIds[0],
            rpc: {
              [vestingNetwork.chainIds[0]]: vestingNetwork.rpcUrls[0],
            },
          };
      }
    },

    getToken(routeName) {
      switch (routeName) {
        case 'Vesting':
          return vestingToken;

        default:
          return vestingToken;
      }
    },
  },
};
</script>
