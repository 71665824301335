import vesting from '../abis/development/pools/vestingPool';

const host = 'https://testnet.bscscan.com/address';
// const host = 'https://polygonscan.com/address';

export default [
  {
    title: 'Vesting Pool',
    contractAddress: '0xc80CCc223D92ee9Bd7357233C49B021A364ddb94',
    minApr: 175,
    abi: vesting.abi,
    url: `${host}/0xc80CCc223D92ee9Bd7357233C49B021A364ddb94`,
  },
];
