<template>
  <div class="card-input-buttons btn-connect-wallet">
    <button v-b-modal.modal-connect-wallet class="btn card-input-button btn-custom btn-none-active">
      <b style="color: white">Connect to wallet</b>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ConnectButton',
};
</script>

<style lang="scss" scoped>
.btn-connect-wallet {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 95px;

    @media (max-width: 820px) {
      margin-top: 35px;
    }
}
.btn-custom {
  width: 200px !important;
}
</style>
