<template>
  <section class="section-header">
    <div>
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div>
          <router-link to="/" active-class="active">
            <img
              src="@/assets/images/nlc_logo.png"
              class="logo-nlc mt-2"
              alt=""
              width="86"
            >
          </router-link>
        </div>
        <div class="d-flex">
          <!-- html after connect success -->
          <div v-if="$store.state.isLogin" class="connect-success">
            <div class="d-none d-md-flex binance-main-net">
              <span class="s-icon" />
              Binance Smart Chain
            </div>
            <div v-b-modal.modal-logout class="mt-2 mt-md-0 wallet-data">
              <div class="wallet-balance">
                {{ toFormattedToken($store.state.account.balance) }}
                {{ $store.state.account.symbol }}
              </div>
              <div class="wallet-address">
                {{ displayAddress }}
              </div>
            </div>
          </div>
          <button
            v-show="!$store.state.isLogin"
            v-b-modal.modal-connect-wallet
            class="mt-md-0 mt-2 btn btn-gold btn-connect-wallet btn-none-active"
          >
            Connect to wallet
          </button>
          <div />
          <!-- end html before connect success -->
          <b-navbar toggleable="lg" type="dark" class="navbar-pc">
            <b-navbar-toggle target="navbar-toggle-collapse" />
          </b-navbar>
          <b-navbar-toggle target="navbar-toggle-collapse" class="mt-2 ml-1 navbar-toggle-collapse-pc fix-btn-menu">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="x" class="icon-close fix-icon-menu" />
              <b-icon v-else icon="list" class="icon-list fix-icon-menu" />
            </template>
          </b-navbar-toggle>
        </div>
      </div>
    </div>

    <!-- modal -->
    <b-modal id="modal-connect-wallet" :hide-footer="true">
      <template #default>
        <connect-to-wallet />
        <template>
          <div id="footer">
            <a class="about-link" href="https://metamask.io/download.html" target="_blank">
              https://metamask.io/download.html
            </a>
            <p class="about">
              If you are using a mobile device, please access using Metamask app browser.
            </p>
          </div>
        </template>
      </template>
    </b-modal>
    <!-- end modal -->

    <!-- modal logout -->
    <b-modal id="modal-logout" :hide-footer="true">
      <template #default>
        <account-wallet />
      </template>
    </b-modal>
    <!-- end modal logout -->
  </section>
</template>

<style scoped lang="scss">
#footer {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  text-align: center;
}
#modal-connect-wallet {
  #footer {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}
.logo-nlc {
  max-width: 57px;
  max-height: 51px;
}
.about-link {
  font-size: 1.1rem;
}
.about {
  font-size: 1rem;
  width: 292px;
  margin: 0 auto;
  text-align: center;
}

.btn.btn-connect-wallet {
  width: 164px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
  background: #323272 !important;
}

::v-deep {
  .modal-header {
    border-bottom: none;
  }
}
.navbar {
  padding: 0.5rem 0 1rem 1rem !important;
}
.navbar-dark .navbar-toggler {
  border-color: #ffffff00;
}
.navbar-toggler {
  padding: 0 0 0 0;
}
.navbar-toggle-collapse-pc {
  display: none;
}
@media (max-width: 799px) {
  .navbar-toggle-collapse-pc {
    display: block;
    font-size: 40px;
    color: #323272;
    margin: 10px 0px 0px 10px;
    &.fix-btn-menu {
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 8px;
      border: 1px solid;
      border-radius: 12px;
      margin-right: 5px;
      &::after {
        content: 'Menu';
        font-size: 13px;
        font-weight: bold;
        color: #323272;
      }
      .fix-icon-menu {
        width: 26px;
        font-size: 26px;
        margin-right: 3px;
      }
    }
  }
  .navbar-pc {
    display: none;
  }
  .logo-nlc {
    width: 57px;
    height: 51px;
  }
  .navbar-toggle-collapse-pc {
    margin: 0;
  }
  .section-header {
    padding: 15px 10px 15px;
  }
}
</style>

<script>
import BaseMixin from '@/mixins/base';
import AccountWallet from '@/components/wallet/AccountWallet';
import ConnectToWallet from '@/components/wallet/ConnectToWallet';

export default {
  name: 'Header',

  components: {
    AccountWallet,
    ConnectToWallet,
  },

  mixins: [BaseMixin],
};
</script>
