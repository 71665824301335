import { METAMASK } from '../constants';

import network from '@/domain/vesting/network';
import token from '@/domain/vesting/token';
import vestingPools from '@/domain/vesting/pools';

export const _convertWeiToEther = (web3, amount) => web3.utils.fromWei(amount, 'ether');

export const _convertEtherToWei = (web3, amount) => web3.utils.toWei(amount, 'ether');

/**
 * It returns the first account returned by the MetaMask extension
 * @returns An array of accounts.
 */
export const _getAccountForMetaMask = async () => {
  if (!window.ethereum) return;

  let accounts = [];
  await window.ethereum
    .request({
      method: 'eth_requestAccounts',
    })
    .then(async (acts) => {
      accounts = acts;
    })
    .catch((error) => {
      if (error.code === 4001) {
        console.log('Permissions needed to continue.');
      } else {
        console.log(error);
      }
      accounts = [];
    });

  return accounts;
};

export const getAccountData = async (web3, token, accountAddresses = []) => {
  try {
    let accounts =
      accountAddresses.length > 0
        ? accountAddresses
        : sessionStorage.getItem('provider') == METAMASK
          ? await _getAccountForMetaMask()
          : await web3.eth.getAccounts();

    let address = accounts[0];

    address = web3.utils.toChecksumAddress(address);
    let contract = new web3.eth.Contract(token.abi, token.contractAddress);

    let balance = await contract.methods.balanceOf(address).call({ from: address });

    balance = web3.utils.toBN(balance).toString();

    let symbol = await contract.methods.symbol().call();

    return {
      address,
      balance,
      symbol,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * It takes a web3 instance and an account address and returns an array of staking pools
 * @param web3 - The web3 instance
 * @param accountAddress - The address of the user's wallet.
 * @returns An array of objects.
 */
export const getPools = async (web3, accountAddress) => {
  try {
    let tokenContract = new web3.eth.Contract(token.abi, token.contractAddress);
    let balanceOfUser = await tokenContract.methods.balanceOf(accountAddress).call({ from: accountAddress });

    let tokenSymbol = await tokenContract.methods.symbol().call();
    let tokenDecimals = await tokenContract.methods.decimals().call();

    let pools = await Promise.all(
      vestingPools.map(async (vestingPool, index) => {
        let poolContract = new web3.eth.Contract(vestingPool.abi, vestingPool.contractAddress);
        let unStakeFee = 0;

        let account = {
          claimable: `${0}`,
          totalAmount: `${0}`,
          balance: `${0}`,
          claimNextTime: `${0}`,
          claimNextAmount: `${0}`,
          claimedAmount: `${0}`,
          lastClaimed: `${0}`,
        };

        if (accountAddress) {
          let accountPoolData = await poolContract.methods.userInfo(accountAddress).call({ from: accountAddress });
          let balance = await poolContract.methods.balanceOf(accountAddress).call({ from: accountAddress });
          account.balance = balance;
          unStakeFee = accountPoolData.unlockPercentage / 100;
          // 1. loop if now > current (current = unlockFrom)
          // => claimable = 0 + (totalAmount * unlockPercentage / percentageDenominator)
          let claimableBalance = await getClaimableBalance(poolContract, accountAddress);
          // ((now - unlockFrom/unlockInterval) + 1) * unlockInterval + unlockFrom
          // => return UTC unix time and claimable amount
          let nextClaimTime = await getNextClaimTime(poolContract, accountAddress);

          // let allowance = await tokenContract.methods
          //   .allowance(accountAddress, vestingPool.contractAddress)
          //   .call({ from: accountAddress });

          account.claimable = claimableBalance > 0 ? web3.utils.toBN(claimableBalance).toString() : `${0}`;
          account.claimedAmount = accountPoolData.claimedAmount;
          account.claimNextTime = nextClaimTime.nextTime;
          account.claimNextAmount = nextClaimTime.amount;
          account.lastClaimed = accountPoolData.lastClaimed;
        }

        return {
          id: index + 1,
          title: vestingPool.title,
          url: vestingPool.url,
          NETWORK: network.name,
          CHAIN_ID: network.chainIds[0],
          coinSymbol: tokenSymbol,
          unStakeFee: unStakeFee,
          stakeTokenAddress: token.contractAddress,
          stakeTokenSymbol: tokenSymbol,
          stakeTokenDecimals: tokenDecimals,
          stakeContractAddress: vestingPool.contractAddress,
          createdAt: new Date(),
          updatedAt: new Date(),
          balanceOfUser,
          account,
        };
      })
    );

    return pools;
  } catch (e) {
    console.error(e);
  }
};

export const getClaimableBalance = async (poolContract, accountAddress) => {
  try {
    return await poolContract.methods
      .claimableBalace(accountAddress)
      .call({ from: accountAddress });
  } catch (e) {
    return 0;
  }
};

export const getNextClaimTime = async (poolContract, accountAddress) => {
  try {
    return await poolContract.methods.nextClaimTime(accountAddress).call({ from: accountAddress });
  } catch (e) {
    return {
      nextTime: 0,
      amount: 0
    };
  }
};
