<template>
  <footer class="footer flex-shrink-0 text-center">
    <img :src="require('@/assets/images/nlc_logo.png')" alt="" width="86">
  </footer>
</template>

<style scoped lang="scss">
.footer {
  padding: 74px 0px;
}
</style>

<script>
export default {
  name: 'Footer'
};
</script>
