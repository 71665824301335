import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/scss/app.scss';

import VueRouter from 'vue-router';
import router from './router';
import store from './store';
import Vue from 'vue';
import App from './App.vue';
import BoxCard from './components/base/cards/BoxCard.vue';
import { BModal, BootstrapVue, IconsPlugin, VBModal } from 'bootstrap-vue';


Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.component('BModal', BModal);
Vue.component('Card', BoxCard);
Vue.directive('b-modal', VBModal);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
});
