<template>
  <div v-if="Object.keys(notifications).length" class="global-notifications">
    <div class="block-notifications">
      <div
        v-for="(notification, index) of notifications"
        :key="index"
        :ref="index"
        :class="[
          'block-notification',
          notification.show || false ? 'notification-show' : '',
          'block-notification-' + (notification.type || 'success'),
        ]"
        class="block-notification block-notification-success"
      >
        <div class="block-notification-icon" />
        <div class="block-notification-contents">
          <div class="block-notification-content-title">
            {{ notification.title || '' }}
          </div>
          <div class="block-notification-content" v-html="notification.content || ''" />
        </div>
        <div class="block-notification-button">
          <span aria-hidden="true" @click="removeNotification(index)">&times;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="@/assets/scss/notification.scss"></style>

<script>
import Vue from 'vue';

export default {
  name: 'Notification',
  data() {
    return {
      /**
       * [notifications description]
       * @type {Array}
       *
       * type : success, error, info, warning
       *
       * using
       * this.$root.$emit('global-notification', {title: 'Foo notification', timeout: 1000, 'type': 'success', 'content': 'xxx'});
       *
       */
      notifications: {},
      defaultTimeout: 5000,
      maxNotification: 4,
    };
  },
  mounted() {
    this.$root.$on('global-notification', this.appendNotification);
    this.initDefaultNotification();
  },
  beforeDestroy() {
    this.$root.$off('global-notification', this.appendNotification);
  },
  methods: {
    appendNotification(notification) {
      this.removeNotificationByMaxSetting();
      let notificationId = (Math.random() + 1).toString(36).substring(7);
      let timeout = notification.timeout || this.defaultTimeout;
      Vue.set(this.notifications, notificationId, notification);
      this.setAutoHiddenNotification(notificationId, timeout);
      this.setClassShow(notificationId);
    },
    removeNotificationByMaxSetting() {
      let maxNotification = this.maxNotification - 1;
      let notificationKeys = Object.keys(this.notifications);
      let notificationNumber = notificationKeys.length;
      let notificationNumberRemove = notificationNumber - maxNotification;
      notificationNumberRemove = parseInt(notificationNumberRemove);
      if (notificationNumberRemove > 0) {
        for (var notificationKey in notificationKeys) {
          if (notificationNumberRemove > 0) {
            this.removeNotification(notificationKeys[notificationKey]);
            notificationNumberRemove--;
          }
        }
      }
    },
    setAutoHiddenNotification(notificationId, timeout) {
      timeout = parseInt(timeout);
      setTimeout(
        function (scope) {
          scope.removeNotification(notificationId);
        },
        timeout,
        this
      );
    },
    setClassShow(notificationId) {
      setTimeout(
        function (scope) {
          scope.showNotification(notificationId);
        },
        10,
        this
      );
    },
    showNotification(notificationId) {
      if (this.notifications.hasOwnProperty(notificationId)) {
        Vue.set(this.notifications[notificationId], 'show', true);
      }
    },
    hideNotification(notificationId) {
      if (this.notifications.hasOwnProperty(notificationId)) {
        Vue.set(this.notifications[notificationId], 'show', false);
      }
    },
    removeNotification(notificationId) {
      this.hideNotification(notificationId);
      setTimeout(
        function (scope) {
          scope.deleteNotification(notificationId);
        },
        600,
        this
      );
    },
    deleteNotification(notificationId) {
      this.$delete(this.notifications, notificationId);
    },
    initDefaultNotification() {
      for (var notificationId in this.notifications) {
        let notification = this.notifications[notificationId];
        let timeout = notification.timeout || this.defaultTimeout;
        this.setAutoHiddenNotification(notificationId, timeout);
        this.setClassShow(notificationId);
      }
    },
  },
};
</script>
