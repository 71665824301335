import network from '@/domain/vesting/network';
import vestingPools from '@/domain/vesting/pools';
import token from '@/domain/vesting/token';

const store = {
  namespaced: true,
  state: {
    pools: vestingPools.map((vestingPool, index) => {
      return {
        id: index + 1,
        title: vestingPool.title,
        url: vestingPool.url,
        NETWORK: network.name,
        CHAIN_ID: network.chainIds[0],
        coinSymbol: token.name,
        unStakeFee: `${0}`,
        stakeTokenAddress: token.contractAddress,
        stakeTokenSymbol: token.name,
        stakeTokenDecimals: token.decimals,
        stakeContractAddress: vestingPool.contractAddress,
        createdAt: new Date(),
        updatedAt: new Date(),
        account: {
          claimable: `${0}`,
          totalAmount: `${0}`,
          balance: `${0}`,
          claimNextTime: `${0}`,
          claimNextAmount: `${0}`,
          claimedAmount: `${0}`,
          lastClaimed: `${0}`,
        },
      };
    }),
  },
  getters: {},
  mutations: {
    updatePools(state, payload) {
      state.pools = payload;
    },
    approvePool(state, payload) {
      state.pools[payload].account.isApproved = true;
    },
    updateDataGachaTicket(state, payload) {
      state.dataGachaTicket = payload;
    },
  },
  actions: {
    updatePools({ commit }, payload) {
      commit('updatePools', payload);
    },
    updateDataGachaTicket({ commit }, payload) {
      commit('updateDataGachaTicket', payload);
    },
    approvePool({ commit }, payload) {
      commit('approvePool', payload);
    },
  },
};

export default store;
