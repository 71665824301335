<template>
  <div class="box-card">
    <div class="box-card-header">
      <slot name="header" />
    </div>
    <div class="box-card-body">
      <slot v-if="$store.state.isLogin" name="body" />
      <connect-button v-else-if="!$store.state.isLogin" />
    </div>
    <div class="box-card-footer">
      <slot name="footer" />
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@media (min-width: 995px) {
  /* < lg */
  .box-card {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<script>
import ConnectButton from '@/components/wallet/ConnectButton';

export default {
  name: 'BoxCard',

  components: {
    ConnectButton,
  },
};
</script>
