import Web3 from 'web3';

const store = {
  namespaced: true,
  state: {
    address: sessionStorage.getItem('address') ? sessionStorage.getItem('address') : null,
    balance: `${0}`,
    symbol: null,
    lockBalance: `${0}`,
  },
  getters: {},
  mutations: {
    updateLockTokenAccountData(state, payload) {
      state.lockBalance = Web3.utils.toBN(payload.balance).toString();
    },
    updateAccountData(state, payload) {
      state.address = payload.address;
      state.balance = Web3.utils.toBN(payload.balance).toString();
      state.symbol = payload.symbol;
    },
    updateBalance(state, payload) {
      state.balance = Web3.utils.toBN(payload).toString();
    },
    updateCanWithDraw(state, payload) {
      state.canWithDraw = payload;
    },
    updateCanWithDeposit(state, payload) {
      state.canWithDeposit = payload;
    },
    decreaseBalance(state, payload) {
      state.balance = Web3.utils.toBN(state.balance).sub(Web3.utils.toBN(payload)).toString();
    },
    increaseBalance(state, payload) {
      state.balance = Web3.utils.toBN(payload).toString();
    },
  },
  actions: {
    updateAccountData({ commit }, payload) {
      commit('updateAccountData', payload);
    },
    updateLockTokenAccountData({ commit }, payload) {
      commit('updateLockTokenAccountData', payload);
    },
    updateBalance({ commit }, payload) {
      commit('updateBalance', payload);
    },
    decreaseBalance({ commit }, payload) {
      commit('decreaseBalance', payload);
    },
    increaseBalance({ commit }, payload) {
      commit('increaseBalance', payload);
    },
    updateCanWithDraw({ commit }, payload) {
      commit('updateCanWithDraw', payload);
    },
    updateCanWithDeposit({ commit }, payload) {
      commit('updateCanWithDeposit', payload);
    },
  },
};

export default store;
