import Vue from 'vue';
import Vuex from 'vuex';
import account from './modules/account';
import web3 from './modules/web3';
import withdraw from './modules/withdraw';
import vesting from './modules/vesting';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: sessionStorage.getItem('address') ? true : false,
  },

  actions: {
    onLogout({ commit }) {
      commit('onLogout');
    },
  },

  mutations: {
    onLogout() {
      localStorage.removeItem('walletconnect');
      sessionStorage.removeItem('address');
      sessionStorage.removeItem('provider');
      window.location.reload();
    },
  },

  modules: {
    account,
    web3,
    withdraw,
    vesting,
  },
});
