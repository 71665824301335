import prodPool from '../abis/production/pools/nlc';

const host = 'https://bscscan.com/address';
//const host = 'https://polygonscan.com/address';

export default [
  {
    title: 'nlc vesting prod',
    contractAddress: '0x1261E055c986473F165a521e28F20c626042cef7',
    minApr: 175,
    abi: prodPool.abi,
    url: `${host}/0x1261E055c986473F165a521e28F20c626042cef7`,
  },
];
