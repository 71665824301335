export default {
  abi: [
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'address', name: 'account', type: 'address' },
        { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'unlockFrom', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'unlockPercentage', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'unlockInterval', type: 'uint256' },
      ],
      name: 'AddUserInfo',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'account', type: 'address' },
        { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'Claim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, internalType: 'address', name: 'account', type: 'address' }],
      name: 'DeleteUserInfo',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: 'address', name: 'lockedToken', type: 'address' }],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
        { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      inputs: [
        { internalType: 'address', name: '_addr', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' },
        { internalType: 'uint256', name: '_unlockFrom', type: 'uint256' },
        { internalType: 'uint256', name: '_unlockPercentage', type: 'uint256' },
        { internalType: 'uint256', name: '_unlockInterval', type: 'uint256' },
      ],
      name: 'addUserInfo',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address[]', name: '_addrs', type: 'address[]' },
        { internalType: 'uint256[]', name: '_amounts', type: 'uint256[]' },
        { internalType: 'uint256', name: '_unlockFrom', type: 'uint256' },
        { internalType: 'uint256', name: '_unlockPercentage', type: 'uint256' },
        { internalType: 'uint256', name: '_unlockInterval', type: 'uint256' },
      ],
      name: 'addUserInfoBatch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
      name: 'claim',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [], name: 'claim', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'claimableBalace',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'deleteUserInfo',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_offset', type: 'uint256' },
        { internalType: 'uint256', name: '_limit', type: 'uint256' },
      ],
      name: 'getUserInfoPaging',
      outputs: [
        {
          components: [
            { internalType: 'bool', name: 'registered', type: 'bool' },
            { internalType: 'address', name: 'addr', type: 'address' },
            { internalType: 'uint256', name: 'totalAmount', type: 'uint256' },
            { internalType: 'uint256', name: 'balance', type: 'uint256' },
            { internalType: 'uint256', name: 'unlockFrom', type: 'uint256' },
            { internalType: 'uint256', name: 'unlockPercentage', type: 'uint256' },
            { internalType: 'uint256', name: 'unlockInterval', type: 'uint256' },
            { internalType: 'uint256', name: 'claimedAmount', type: 'uint256' },
            { internalType: 'uint256', name: 'lastClaimed', type: 'uint256' },
          ],
          internalType: 'struct TokenLocker.UserInfo[]',
          name: 'users',
          type: 'tuple[]',
        },
        { internalType: 'uint256', name: 'nextOffset', type: 'uint256' },
        { internalType: 'uint256', name: 'total', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getUserListLength',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_lockedToken', type: 'address' }],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'initialized',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'lockedToken',
      outputs: [{ internalType: 'contract IBEP20', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'nextClaimTime',
      outputs: [
        { internalType: 'uint256', name: 'nextTime', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'percentageDenominator',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'userInfo',
      outputs: [
        { internalType: 'bool', name: 'registered', type: 'bool' },
        { internalType: 'address', name: 'addr', type: 'address' },
        { internalType: 'uint256', name: 'totalAmount', type: 'uint256' },
        { internalType: 'uint256', name: 'balance', type: 'uint256' },
        { internalType: 'uint256', name: 'unlockFrom', type: 'uint256' },
        { internalType: 'uint256', name: 'unlockPercentage', type: 'uint256' },
        { internalType: 'uint256', name: 'unlockInterval', type: 'uint256' },
        { internalType: 'uint256', name: 'claimedAmount', type: 'uint256' },
        { internalType: 'uint256', name: 'lastClaimed', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'userList',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
  ],
};
