const store = {
  namespaced: true,
  state: {
    vestingPool: null,
  },
  getters: {},
  mutations: {
    updateVestingPool(state, payload) {
      state.vestingPool = payload;
    },
  },
  actions: {
    updateVestingPool({ commit }, payload) {
      commit('updateVestingPool', payload);
    },
  },
};

export default store;
